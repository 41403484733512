<template>
  <div class="tab">
      <ul>
        <li :class="activeIdx === idx ? 'active' : ''" v-for="(item, idx) in timeArr" :key="item" @click="handleTimeChange(idx)">{{item.name}}</li>
      </ul>
      <el-date-picker
        v-if="showCustom"
        class="date-picker"
        unlink-panels
        v-model="value1"
        type="date"
        value="YYYY-MM"
        value-format="yyyy-MM-dd"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        @change='handleChange'
      />
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props:{
    init: {
      type: Boolean,
      default: false
    },
    timeFilter: {
      type: Array,
      default: []
    },
    isCustom:{
      type: Boolean,
      default: true
    },
    initShowInput: {
      type: Boolean,
      default: false
    }
  },
  setup(props,contex) {
    const dataMap = reactive({
      activeIdx: 0,
      showCustom: false,
      value1: '',
      timeArr: [
        {
          name: '日',
          val: "DAY",
          periodType: 1,
          idx: 0
        },{
          name: '周',
          val: "WEEK",
          periodType: 2,
          idx: 1
        },{
          name: '月',
          val: "MONTH",
          periodType: 3,
          idx: 2
        },{
          name: '季',
          val: "QUARTER",
          periodType: 4,
          idx: 3
        },{
          name: '年',
          val: "YEAR",
          periodType: 5,
          idx: 4
        },{
          name: '自定义',
          type: 'custom'
        }
      ],
    })
    //日期筛选
    if( props.timeFilter && props.timeFilter.length ){
      dataMap.timeArr = dataMap.timeArr.filter((item:any)=>props.timeFilter?.includes(item.val))
    }
    //不显示自定义
    if( !props.isCustom ){
      dataMap.timeArr = dataMap.timeArr.slice(0,-1)
    }
    //默认展示日期选择框
    if( props.initShowInput ){
      dataMap.showCustom = true
    }

    //初始化请求接口
    if ( props.init ){
      contex.emit('input', dataMap.timeArr[dataMap.activeIdx])
    }

    //时间切换
    const handleTimeChange = (idx:number) => {
      dataMap.activeIdx = idx
      if( dataMap.timeArr[idx].type === "custom" ){
          dataMap.showCustom = true
          return false
      }else{
          dataMap.showCustom = props.initShowInput ? true : false
      }
      contex.emit('input', dataMap.timeArr[dataMap.activeIdx])
    }

    const handleChange = (val:any) => {
      contex.emit('input', {custom: !val ? val : window.moment(val).format('YYYY-MM-DD')})
    }

    return {
      ...toRefs(dataMap),
      handleTimeChange,
      handleChange
    };
  },
});
</script>
<style lang='scss' scoped>
.tab{
    z-index: 99;
    position: fixed;
    top: 10px;
    right: 10px;
    display: flex;
    ul{
      display: flex;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 10px;
      li{
        width: 52px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        background: rgb(255, 255, 255);
        text-align: center;
        color: rgb(102, 102, 102);
        border-top: 1px solid rgb(221, 221, 221);
        border-bottom: 1px solid rgb(221, 221, 221);
        border-left: 1px solid rgb(221, 221, 221);
        border-image: initial;
        border-right: none;
        &:last-child{
          border-right: 1px solid rgb(221, 221, 221);
          border-radius: 0px 4px 4px 0px;
        }
        &.active{
          color: rgb(255, 255, 255);
          background: #1B56AC;
          border-right-color: transparent;
          border-left-color: transparent;
          border-top: none;
          border-bottom: none;
        }
      }
    }
    ::v-deep .el-input {
      line-height: 32px;
      input{
        height: 32px!important;
      }
    }
  }
</style>
