<template>
  <div class="container">
<!--    <div class="tab">-->
<!--      <ul>-->
<!--        <li :class="activeIdx === idx ? 'active' : ''" v-for="(item, idx) in timeArr" :key="item" @click="handleTimeChange(idx)">{{item}}</li>-->
<!--      </ul>-->
<!--    </div>-->

    <dateComponent @input="handleTimeChange" :init="true" :initShowInput="true" :isCustom="false" />
    <div class="title1">
        <span>三大指数涨跌</span>
        <div>数据更新时间：{{moment(threeIndexChg.updateTime, 'YYYY-MM-DD').format('YYYY-MM-DD')}}</div>
    </div>
    <chart-a :chartData="threeIndexChg" id="chart-a" />
    <div class="des" v-if="threeIndexChg.summary" v-html="getTextColor(threeIndexChg.summary)"></div>
    <div class="title1">
      <span>板块涨跌回顾</span>
      <div>数据更新时间：{{moment(boardIncr.updateTime, 'YYYY-MM-DD').format('YYYY-MM-DD')}}</div>
    </div>
    <chart-b
      :chartData="boardIncr"
      id="chart-b"
    />
    <div class="title2">
       <span>申万一级行业涨幅TOP5</span>
     </div>
    <el-table
        class="scgl-table"
        :data="boardIncr.incrTop || []"
        border
        style="width: 100%"
      >
        <el-table-column
          min-width="20%"
          prop="boardName"
          align="center"
          label="板块名称"
        />
        <el-table-column
          min-width="20%"
          prop="incr"
          align="center"
          :label='timeArr[activeIdx] + "涨跌幅(%)"'
        >
          <template #default='{row}'>
            <div v-dClass='row.incr'>{{row.incr}}%</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="25%"
          prop="mainFunds"
          align="center"
          label="主力动向(亿)"
        >
          <template #default='{row}'>
            <div>{{(row.mainFunds / 100000000).toFixed(2)}}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="15%"
          prop="peTtm"
          align="center"
          label="PE"
        />
        <el-table-column
          min-width="20%"
          prop="peQuantile"
          align="center"
          label="PE分位数"
        />
    </el-table>
    <div class="title2">
       <span class="green">申万一级行业跌幅TOP5</span>
     </div>
    <el-table
      class="scgl-table"
        :data="boardIncr.decrTop || []"
        border
        style="width: 100%"
      >
        <el-table-column
          min-width="20%"
          prop="boardName"
          align="center"
          label="板块名称"
        />
        <el-table-column
          min-width="20%"
          prop="incr"
          align="center"
          :label='timeArr[activeIdx] + "涨跌幅(%)"'
        >
          <template #default='{row}'>
            <div v-dClass='row.incr'>{{row.incr}}%</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="25%"
          prop="mainFunds"
          align="center"
          label="主力动向(亿)"
        >
          <template #default='{row}'>
            <div>{{(row.mainFunds / 100000000).toFixed(2)}}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="15%"
          prop="peTtm"
          align="center"
          label="PE"
        />
        <el-table-column
          min-width="20%"
          prop="peQuantile"
          align="center"
          label="PE分位数"
        />
    </el-table>
    <div class="des" v-if="boardIncr.summary" v-html="getTextColor(boardIncr.summary)"></div>
    <div class="title1">
        <span>牛熊股回顾</span>
        <div>数据更新时间：{{moment(stockRankList.updateTime, 'YYYY-MM-DD').format('YYYY-MM-DD')}}</div>
    </div>
    <chart-c
      :chartData="stockRankList"
      id="chart-c"/>
    <div class="title2">
       <span>个股涨幅榜TOP5</span>
     </div>
    <el-table
      class="scgl-table"
        :data="stockRankList.riseList || []"
        border
        style="width: 100%"
      >
        <el-table-column
          min-width='20%'
          prop="code"
          align="center"
          label="证券代码"
        />
        <el-table-column
          min-width='20%'
          prop="name"
          align="center"
          label="证券名称"
        >
        </el-table-column>
        <el-table-column
          min-width='20%'
          prop="incr"
          align="center"
          :label='timeArr[activeIdx] + "涨跌幅(%)"'
        >
          <template #default="{row}">
            <div class="red">{{row.incr}}%</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width='20%'
          prop="pettm"
          align="center"
          label="PE百分位(%)"
        />
        <el-table-column
          min-width='20%'
          prop="mainIn"
          align="center"
          label="主力动向(亿)"
        >
          <template #default="{row}">
            <div>{{(row.mainIn / 100000000).toFixed(2)}}</div>
          </template>
        </el-table-column>
    </el-table>
    <div class="title2">
       <span class="green">个股跌幅榜TOP5</span>
     </div>
    <el-table
        class="scgl-table"
        :data="stockRankList.fallList || []"
        border
        style="width: 100%"
      >
        <el-table-column
          min-width='20%'
          prop="code"
          align="center"
          label="证券代码"
        />
        <el-table-column
          min-width='20%'
          prop="name"
          align="center"
          label="证券名称"
        >
        </el-table-column>
        <el-table-column
          min-width='20%'
          prop="incr"
          align="center"
          :label='timeArr[activeIdx] + "涨跌幅(%)"'
        >
          <template #default="{row}">
            <div class="green">{{row.incr}}%</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width='20%'
          prop="pettm"
          align="center"
          label="PE百分位(%)"
        />
        <el-table-column
          min-width='20%'
          prop="mainIn"
          align="center"
          label="主力动向(亿)"
        >
          <template #default="{row}">
            <div>{{(row.mainIn / 100000000).toFixed(2)}}</div>
          </template>
        </el-table-column>
    </el-table>
    <div class="des" v-if="stockRankList.riseSummary" >
      <div v-html="getTextColor(stockRankList.riseSummary)"></div>
      <div v-html="getTextColor(stockRankList.fallSummary)"></div>
    </div>
    <div class="title1">
        <span>主力资金流向</span>
        <div>数据更新时间：{{moment(stockFlowList.updateTime, 'YYYY-MM-DD').format('YYYY-MM-DD')}}</div>
    </div>
    <chart-d :chartData="stockFlowList" chartKey="incrTop" chartValue="mainIn" chartTitle="主力资金净流入(亿元)"  id="chart-mainforce-inflow"/>
    <chart-d :chartData="stockFlowList" chartKey="decrTop" chartValue="mainIn"  id="chart-mainforce-outflow"/>
    <div class="title2">
      <span class="blue">主力资金流向TOP5</span>
    </div>
    <el-table
        class="scgl-table"
        :data="stockFlowList.tableData || []"
        border
        style="width: 100%"
      >
        <el-table-column
          min-width='20%'
          prop="stockCode1"
          align="center"
          label="股票代码"
        />
        <el-table-column
          min-width='20%'
          prop="stockName1"
          align="center"
          label="股票简称"
        >
        </el-table-column>
        <el-table-column
          min-width='20%'
          prop="mainIn1"
          align="center"
          label="净买入额 (亿元)"
        >
          <template #default="{row}">
            <div v-dClass="row.mainIn1">{{row.mainIn1}}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width='20%'
          prop="stockCode"
          align="center"
          label="股票代码"
        />
        <el-table-column
          min-width='20%'
          prop="stockName"
          align="center"
          label="股票简称"
        >
        </el-table-column>
        <el-table-column
          min-width='20%'
          prop="mainIn"
          align="center"
          label="净买入额 (亿元)"
        >
          <template #default="{row}">
            <div v-dClass="row.mainIn">{{row.mainIn}}</div>
          </template>
        </el-table-column>
    </el-table>
    <div class="des" v-if="stockFlowList.summary" v-html="getTextColor(stockFlowList.summary)" />
    <div class="title1">
       <span>换手率</span>
      <div>数据更新时间：{{moment(stockTurnoverList.updateTime, 'YYYY-MM-DD').format('YYYY-MM-DD')}}</div>
    </div>
    <chart-e :chartData="stockTurnoverList" id="chart-e"/>
    <div class="des" v-if="stockTurnoverList.summary" v-html="getTextColor(stockTurnoverList.summary)" />
    <div class="title1">
       <span>北向资金流向</span>
       <div>数据更新时间：{{moment(stockNorthboundFlowList.updateTime, 'YYYY-MM-DD').format('YYYY-MM-DD')}}</div>
    </div>
    <chart-d :chartData="stockNorthboundFlowList" chartKey="incrTop" chartValue="addMarketCap" chartTitle="北向资金净买入额(亿元)"  id="chart-northbound-inflow"/>
    <chart-d :chartData="stockNorthboundFlowList" chartKey="decrTop" chartValue="addMarketCap" chartTitle="北向资金净卖出额(亿元)"  id="chart-northbound-outflow"/>
    <div class="title2">
       <span class="blue">北向资金流向TOP5</span>
    </div>
    <el-table
        class="scgl-table"
        :data="stockNorthboundFlowList.tableData || []"
        border
        style="width: 100%"
      >
        <el-table-column
          min-width='20%'
          prop="stockCode1"
          align="center"
          label="股票代码"
        />
        <el-table-column
          min-width='20%'
          prop="stockName1"
          align="center"
          label="股票简称"
        >
        </el-table-column>
        <el-table-column
          min-width='20%'
          prop="addMarketCap1"
          align="center"
          label="净买入额 (亿元)"
        >
          <template #default="{row}">
            <div v-dClass="row.addMarketCap1">{{row.addMarketCap1}}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width='20%'
          prop="stockCode"
          align="center"
          label="股票代码"
        />
        <el-table-column
          min-width='20%'
          prop="stockName"
          align="center"
          label="股票简称"
        >
        </el-table-column>
        <el-table-column
          min-width='20%'
          prop="addMarketCap"
          align="center"
          label="净买入额 (亿元)"
        >
          <template #default="{row}">
            <div v-dClass="row.addMarketCap">{{row.addMarketCap}}</div>
          </template>
        </el-table-column>
    </el-table>
    <div class="des" v-if="stockNorthboundFlowList.summary" v-html="getTextColor(stockNorthboundFlowList.summary)" />
    <div class="title1">
      <span>融资融券动向</span>
      <div>数据更新时间：{{moment(marginFlowList.updateTime, 'YYYY-MM-DD').format('YYYY-MM-DD')}}</div>
    </div>
    <chart-d  :chartData="marginFlowList" chartKey="incrTop" chartValue="buyAmount" chartTitle="融资资金净买入额(亿元)"  id="chart-margintrading-inflow"/>
    <chart-d  :chartData="marginFlowList" chartKey="decrTop" chartValue="sellAmount" chartTitle="融资资金净卖出额(亿元)"  id="chart-margintrading-outflow"/>
    <div class="title2">
       <span class="blue">融资资金净买入、卖出TOP5</span>
    </div>
    <el-table
        class="scgl-table"
        :data="marginFlowList.tableData || []"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="stockCode"
          align="center"
          label="股票代码"
        />
        <el-table-column
          prop="stockName"
          align="center"
          label="股票简称"
        />
        <el-table-column
          prop="buyAmount"
          align="center"
          label="净买入额 (亿元)"
        />
        <el-table-column
          prop="rzyezb"
          align="center"
          label="融资余额/ 流通市值(%)"
        />

        <el-table-column
          prop="stockCode1"
          align="center"
          label="股票代码"
        />
        <el-table-column
          prop="stockName1"
          align="center"
          label="股票简称"
        />
        <el-table-column
          prop="sellAmount1"
          align="center"
          label="净卖出额 (亿元)"
        />
        <el-table-column
          prop="rzyezb1"
          align="center"
          label="融券余额/ 流通市值(%)"
        />
    </el-table>
    <div class="des" v-if="marginFlowList.summary" v-html="getTextColor(marginFlowList.summary)" />
  </div>
</template>

<script lang="ts">
import moment from 'moment'
import dateComponent from '@/components/timeLevel/Index.vue'
import chartA from "./charts/chartA.vue";
import chartB from "./charts/chartB.vue";
import chartC from "./charts/chartC.vue";
import chartD from "./charts/chartD.vue";
import chartE from "./charts/chartE.vue";
import { defineComponent, reactive, toRefs } from 'vue'
import { getIndexSummary, getMarketSummaryBoardIncr, getStockRankList, getStockFlow, getStockTurnover, getNorthboundFlow, getMarginFlow } from '@/apis/market-overview'
export default defineComponent({
  components: {
    chartA,
    chartB,
    chartC,
    chartD,
    chartE,
    dateComponent
  },
  setup() {
    const dataMap = reactive({
      activeIdx: 0,
      timeArr: ['日','周','月','季','年'],
      threeIndexChg: {},
      boardIncr: {},
      stockRankList: {},
      stockFlowList: {},
      stockTurnoverList: {},
      stockNorthboundFlowList: {},
      marginFlowList: {}
    })
    const time = {
      a: ['day','week','month','quarter','year'],
      b: ['1','2','3','4','5'],
    }
    let date:any = undefined

    const fetchData = async () => {
      const threeIndexChg = await getIndexSummary({ periodType: time.a[dataMap.activeIdx], date }) //日期类型 [day:日 week:周  month:月 quarter:季 year:年]
      dataMap.threeIndexChg = (threeIndexChg as any).data;

      const boardIncr = await getMarketSummaryBoardIncr({ boardType: 'swl1', periodType: time.b[dataMap.activeIdx], date })
      dataMap.boardIncr = (boardIncr as any).data;

      const stockRankList = await getStockRankList({ type: time.b[dataMap.activeIdx], date })
      dataMap.stockRankList = (stockRankList as any).data;

      const stockFlowList = await getStockFlow({ periodType: time.b[dataMap.activeIdx], date }); //1:日,2:周,3:月,4:季,5:年
      (stockFlowList as any).data.tableData = (stockFlowList as any).data.decrTop.map((item:any, index:number)=>{
        const innerItem = (stockFlowList as any).data.incrTop[index]
        return {
          mainIn: (item.mainIn / 100000000).toFixed(2),
          stockCode: item.stockCode,
          stockName: item.stockName,
          mainIn1: (innerItem.mainIn / 100000000).toFixed(2),
          stockCode1: innerItem.stockCode,
          stockName1: innerItem.stockName
        }
      })
      dataMap.stockFlowList = (stockFlowList as any).data;

      const stockTurnoverList = await getStockTurnover({ periodType: time.b[dataMap.activeIdx], date }) //1:日,2:周,3:月,4:季,5:年
      dataMap.stockTurnoverList = (stockTurnoverList as any).data;

      const stockNorthboundFlowList = await getNorthboundFlow({ periodType: time.b[dataMap.activeIdx], date }); //1:日,2:周,3:月,4:季,5:年
      (stockNorthboundFlowList as any).data.tableData = (stockNorthboundFlowList as any).data.decrTop.map((item:any, index:number)=>{
        const innerItem = (stockNorthboundFlowList as any).data.incrTop[index]
        return {
          addMarketCap: (item.addMarketCap / 100000000).toFixed(2),
          stockCode: item.stockCode,
          stockName: item.stockName,
          addMarketCap1: (innerItem.addMarketCap / 100000000).toFixed(2),
          stockCode1: innerItem.stockCode,
          stockName1: innerItem.stockName
        }
      })
      dataMap.stockNorthboundFlowList = (stockNorthboundFlowList as any).data;

      const marginFlowList = await getMarginFlow({ periodType: time.b[dataMap.activeIdx], date }); //1:日,2:周,3:月,4:季,5:年
      (marginFlowList as any).data.tableData = (marginFlowList as any).data.incrTop.map((item:any, index:number)=>{
        const innerItem = (marginFlowList as any).data.decrTop[index]
        return {
          buyAmount: (item.buyAmount / 100000000).toFixed(2),
          rzyezb: item.rzyezb,
          sellAmount: item.sellAmount,
          stockCode: item.stockCode,
          stockName: item.stockName,
          buyAmount1: innerItem ? (innerItem.buyAmount / 100000000).toFixed(2) : '-',
          rzyezb1: innerItem ? innerItem.rzyezb : '-',
          sellAmount1: innerItem ? (innerItem.sellAmount / 100000000).toFixed(2) : '-',
          stockCode1: innerItem ? innerItem.stockCode : '-',
          stockName1: innerItem ? innerItem.stockName : '-'
        }
      })
      dataMap.marginFlowList = (marginFlowList as any).data;
    }

    //时间切换
    const handleTimeChange = (item:any) => {
      if( item.hasOwnProperty('custom') ){
        date = item.custom
      }else{
        dataMap.activeIdx = item.idx
      }
      fetchData()
    }

    //格式化富文本颜色
    const getTextColor = (text:any) => {
      if( !text ){
        return text
      }
      const reg = new RegExp('\\[(.+?)\\]',"g");
      const reg2 = new RegExp('\\<(.+?)\\>',"g");
      return text.replace(reg2,"<span style='color:red'>$1</span>").replace(reg,"<span style='color:green'>$1</span>");
    }

    return {
      ...toRefs(dataMap),
      moment,
      getTextColor,
      handleTimeChange
    };
  },
});
</script>
<style lang='scss' scoped>
.container{
  padding: 15px;
  background: url("https://jufxt-investment.oss-cn-beijing.aliyuncs.com/calf/scgl-bg.jpg");
  .blue{
    color: #1B56AC;
  }
  .tab{
    z-index: 99;
    position: fixed;
    top: 10px;
    right: 10px;
    ul{
      display: flex;
      border-radius: 4px;
      overflow: hidden;
      li{
        width: 52px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        background: rgb(255, 255, 255);
        text-align: center;
        color: rgb(102, 102, 102);
        border-top: 1px solid rgb(221, 221, 221);
        border-bottom: 1px solid rgb(221, 221, 221);
        border-left: 1px solid rgb(221, 221, 221);
        border-image: initial;
        border-right: none;
        &:last-child{
          border-right: 1px solid rgb(221, 221, 221);
          border-radius: 0px 4px 4px 0px;
        }
        &.active{
          color: rgb(255, 255, 255);
          background: #1B56AC;
          border-right-color: transparent;
          border-left-color: transparent;
          border-top: none;
          border-bottom: none;
        }
      }
    }
  }
  .title1{
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #FFFFFF;
    margin: 57px 0 20px;
    div{
      color: #ccc;
      font-size: 12px;
      margin-top: 10px;
      text-align: right;
    }
    span{
      display: inline-block;
      font-weight: 600;
      padding: 3px 10px;
      background: linear-gradient(135deg, #FF431A 0%, #FE0D2D 100%);
    }
  }
  .title2{
    font-size: 15px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #A61C1A;
    line-height: 21px;
    text-align: center;
    margin: 25px 0 10px;
  }
  .des{
    //width: 346px;
    background: #F6F7FB;
    margin: 20px auto;
    line-height: 24px;
    font-size: 16px;
    padding: 13px;
    div:nth-child(1){
      margin-bottom: 20px
    };
  }
}

</style>
<style lang='scss'>
.scgl-table{
  font-size: 12px;
  .cell{
    padding: 0!important;
  }
  .el-table__header-wrapper{
    th{
      background: #E4EFF8;
      border-right: 1px solid #fff;
    }
  }
  tbody{
    tr{
      &:nth-child(odd) {
        td{
          background: #F6FBFF;
        }
      }
      &:nth-child(even) {
        td{
          background: #E4EFF8;
        }
      }
      td {
        border-right: 1px solid #fff;
      }
    }
  }
}
</style>
